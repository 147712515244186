
import './App.css';
import React from 'react';
import WorkSpace from './components/WorkSpace/WorkSpace';
import Sidebar from './components/Sidebar/Sidebar';
import FileCenterProvider from './FileCenterProvider';

function App() {

  return (
    <div className="App">
      <FileCenterProvider>
        <WorkSpace />
        <Sidebar />
      </FileCenterProvider>
    </div>
  );
}

export default App;
