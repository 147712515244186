function initMeasureText() {
    const div = document.createElement('div')
    div.id = 'measure-text'
    div.innerHTML = ""
    div.style.cssText = "visibility: hidden;display: 'inline-block';position:absolute;white-space: nowrap;"
    document.body.appendChild(div)
}

initMeasureText()


function getImageRatio(originWidth, originHeight, containerWidth, containerHeight) {
    if (originWidth <= containerWidth && originHeight <= containerHeight) {
        return 1;
    }

    const widthRatio = containerWidth / originWidth;
    const heightRatio = containerHeight / originHeight;
    return Math.min(widthRatio, heightRatio)
}

function getTextWH(text, font) {
    const { fontFamily, fontSize } = font
    const div = document.getElementById('measure-text')
    if (!div) {
        return { width: 0, height: 0 }
    }
    div.innerHTML = text
    div.style.fontFamily = fontFamily
    div.style.fontSize = fontSize + 'px'
    const rect = div.getBoundingClientRect()
    return { width: rect.width, height: rect.height }
}

function createCanvas() {
    const canvas = document.createElement("canvas");
    return canvas
}

function resizeCanvas(canvas, width, height) {
    canvas.width = width
    canvas.height = height
}

function canvas2Img(canvas, imgType, quality = 1) {
    return new Promise((resolve, reject) => {
        canvas.toBlob(function (blob) {
            var url = URL.createObjectURL(blob);
            resolve(url)
        }, imgType, quality);
    })
}

function download(url, fileName) {
    // 创建一个临时链接并模拟点击下载
    var a = document.createElement('a');
    a.href = url
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export { getImageRatio, getTextWH, createCanvas, resizeCanvas, canvas2Img, download }