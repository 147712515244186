import './ThumbnailList.css';
import React, { useContext, useRef } from 'react';
import { fileCenter } from '../../FileCenter';
import { FileCenterContext } from '../../FileCenterProvider';
import {
    ClearOutlined
} from '@ant-design/icons';

function ThumbnailList(props) {
    const { selectedItem, onClick } = props;
    const { fileIdList, setFileIdList } = useContext(FileCenterContext);
    const fileInfoList = fileIdList.map(fileId => fileCenter.getFileInfo(fileId))
    const refFileInput = useRef(null)

    function renderThumbnailItem(fileInfo) {
        const cs = 'thumbnail-img ' + (fileInfo.fileId === selectedItem.fileId ? 'thumbnail-img-selected' : '')
        console.log('key ', fileInfo.fileId)
        return (
            <div className='thumbnail-item' key={fileInfo.fileId}>
                <img alt='error' className={cs} src={fileInfo.fileDataUrl} onClick={() => { onClick(fileInfo) }} />
            </div>
        )
    }

    function handleClear() {
        fileCenter.clear()
        setFileIdList([])
    }

    function handleAdd() {
        console.log('handleAdd')
        // @ts-ignore
        refFileInput.current?.click()
    }

    function handleChange(event) {
        const files = event.target.files;
        fileCenter.handleUpload(files, setFileIdList)
    }

    return (
        <div className='thumbnail-container'>
            <div className='thumbnail-btn-container'>
                <div className='btn-pointer' onClick={handleAdd}>
                    <div className='circle-add'>
                        +
                    </div>
                    <span className='text-add'>添加</span>
                    <input ref={refFileInput} className="file-input" type="file" accept="image/*" multiple onChange={handleChange} />
                </div>
                <div className='btn-pointer' onClick={handleClear}>
                    <ClearOutlined className="text-clear" />
                    <span className='text-clear'>清空</span>
                </div>
            </div>
            <div className='thumbnail-list-container'>
                {fileInfoList.map(fileInfo => { return renderThumbnailItem(fileInfo) })}
            </div>
        </div>
    )
}

export default React.memo(ThumbnailList)