import React, { createContext, useState } from 'react';

export const FileCenterContext = createContext({
    fileIdList: [],
    setFileIdList: Function.prototype,
    //水印类型
    waterMarkType: 'text',
    setWaterMarkType: Function.prototype,
    //水印内容
    waterMark: '',
    setWaterMark: Function.prototype,
    fontName: 'Microsoft YaHei',
    setFontName: Function.prototype,
    // 在原始图片上的字体大小
    fontSize: 48,
    setFontSize: Function.prototype,
    fontColor: '#1677ff',
    setFontColor: Function.prototype,
    //方向
    angel: 0,
    setAngel: Function.prototype,
    //透明度
    tran: 0,
    setTran: Function.prototype,
    //位置类型，0 平铺 1 九宫格 2 自定义
    posType: 0,
    setPosType: Function.prototype,
    // 水印内容相对于图片的偏移量
    offsetLeft: 0,
    setOffsetLeft: Function.prototype,
    // 水印内容相对于图片的偏移量
    offsetTop: 0,
    setOffsetTop: Function.prototype,
    cellIndex: 0,
    setCellIndex: Function.prototype,
});

const FileCenterProvider = ({ children }) => {
    const [fileIdList, setFileIdList] = useState([]);

    const [waterMarkType, setWaterMarkType] = useState('text');
    const [waterMark, setWaterMark] = useState('');
    const [fontName, setFontName] = useState('Microsoft YaHei');
    const [fontSize, setFontSize] = useState(48);
    const [fontColor, setFontColor] = useState('#1677ff');
    const [angel, setAngel] = useState(0);
    const [tran, setTran] = useState(0);
    const [posType, setPosType] = useState(0);
    const [offsetLeft, setOffsetLeft] = useState(50);
    const [offsetTop, setOffsetTop] = useState(50);
    const [cellIndex, setCellIndex] = useState(4);

    return (
        <FileCenterContext.Provider value={{
            fileIdList,
            setFileIdList,
            waterMarkType,
            setWaterMarkType,
            waterMark,
            setWaterMark,
            fontName,
            setFontName,
            fontSize,
            setFontSize,
            fontColor,
            setFontColor,
            angel,
            setAngel,
            tran,
            setTran,
            posType,
            setPosType,
            offsetLeft,
            setOffsetLeft,
            offsetTop,
            setOffsetTop,
            cellIndex,
            setCellIndex,
        }}>
            {children}
        </FileCenterContext.Provider>
    );
};

export default FileCenterProvider;
