
function generateUniqueId() {
    return Date.now() + '-' + Math.floor(Math.random() * 10000);
}

class FileInfo {
    constructor(file, image, fileDataUrl) {
        this.fileId = generateUniqueId()
        this.file = file
        this.image = image
        this.fileDataUrl = fileDataUrl
    }
}

class FileCenter {

    constructor() {
        this.fileMap = new Map()
    }

    getFileInfo(fileId) {
        return this.fileMap.get(fileId)
    }

    setFileInfo(fileId, fileInfo) {
        return this.fileMap.set(fileId, fileInfo)
    }

    getFileInfoList() {
        const result = []
        this.fileMap.forEach((value, key) => {
            result.push(value)
        })
        return result
    }

    getCount() {
        return this.fileMap.size
    }

    clear() {
        this.fileMap.clear()
    }

    getTest() {
        return "FileCenter"
    }

    handleUpload(files, cb) {
        const self = this
        let loadedCount = 0
        Array.from(files).map(file => {

            const reader = new FileReader();
            reader.onload = function (e) {
                if (!e.target) return;
                if (typeof e.target.result !== 'string') {
                    return;
                }
                const img = new Image();
                // @ts-ignore
                img.src = e.target.result;
                const dataUrl = e.target.result;
                const fileInfo = new FileInfo(file, img, dataUrl)
                console.log('fileInfo.fileId ------ ', fileInfo.fileId)
                self.setFileInfo(fileInfo.fileId, fileInfo)
                loadedCount += 1
                if (loadedCount === files.length) {
                    const list = self.getFileInfoList().map(fileInfo => fileInfo.fileId)
                    cb && cb(list)
                }
            }
            reader.readAsDataURL(file);
        })
    }
}

const fileCenter = new FileCenter();
export { FileInfo, fileCenter }

