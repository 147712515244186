
import './Sidebar.css';
import React, { useContext } from 'react';
import { Radio, Space, Select, ColorPicker, Slider, Col } from 'antd';
import { Row } from 'antd';
import GridSelector from './GridSelector';
import { Button } from 'antd';
import { FileCenterContext } from '../../FileCenterProvider';
import { canvas2Img, createCanvas, download, getTextWH } from '../../utils';
import { fileCenter } from '../../FileCenter';


function Sidebar() {

    const {
        waterMarkType,
        setWaterMarkType,
        waterMark,
        setWaterMark,
        fontName,
        setFontName,
        fontSize,
        setFontSize,
        fontColor,
        setFontColor,
        angel,
        setAngel,
        tran,
        setTran,
        posType,
        setPosType,
        offsetLeft,
        setOffsetLeft,
        offsetTop,
        setOffsetTop,
        cellIndex,
        setCellIndex,
    }
        = useContext(FileCenterContext);

    function handlePosTypeChange(e) {
        setPosType(e.target.value);
        if (e.target.value === 1) {
            handleCellIndexChange(4)
        }
    };

    function handleCellIndexChange(index) {
        let left = 50;
        let top = 50;
        switch (index) {
            case 0:
                left = 20;
                top = 20;
                break;
            case 1:
                left = 50;
                top = 20;
                break;
            case 2:
                left = 80;
                top = 20;
                break;
            case 3:
                left = 20;
                top = 50;
                break;
            case 4:
                left = 50;
                top = 50;
                break;
            case 5:
                left = 80;
                top = 50;
                break;
            case 6:
                left = 20;
                top = 80;
                break;
            case 7:
                left = 50;
                top = 80;
                break;
            case 8:
                left = 80;
                top = 80;
                break;
            default:
                left = 50;
                top = 50;
        }
        setCellIndex(index);
        setOffsetLeft(left);
        setOffsetTop(top);
    }

    function handleColorChange(color) {
        setFontColor(color.toHexString());
    }

    async function handleExport() {
        const canvas = createCanvas();
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }

        const { width: waterMarkWidth, height: waterMarkHeight } = getTextWH(waterMark, { fontFamily: fontName, fontSize });
        const fileList = fileCenter.getFileInfoList();

        for (let fileInfo of fileList) {
            const { image: img, file } = fileInfo;
            const { width, height } = img;
            canvas.width = width;
            canvas.height = height;
            // 当你设置 <canvas> 元素的 width 和 height 属性时，会重置画布的内容,并且重置绘图上下文（ctx）

            ctx.fillStyle = fontColor;
            ctx.font = `${fontSize}px ${fontName},sans-serif`;
            ctx.textBaseline = 'top';
            ctx.textAlign = 'start';

            ctx.drawImage(img, 0, 0, width, height);

            if (posType !== 0) {
                const waterMarkLeft = offsetLeft / 100 * width - waterMarkWidth / 2
                const waterMarkTop = offsetTop / 100 * height - waterMarkHeight / 2

                ctx.translate(waterMarkLeft, waterMarkTop);
                ctx.rotate(angel * Math.PI / 180);
                ctx.globalAlpha = 1 - tran / 100;
                ctx.fillText(waterMark, 0, 0);
            } else {
                const start = fontSize
                const distanceX = fontSize * 2 + waterMarkWidth
                const distanceY = fontSize * 2 + waterMarkHeight
                let i = 0;
                while (start + i * distanceX < width) {
                    let j = 0;
                    while (start + j * distanceY < height) {
                        const curWaterMarkLeft = start + i * distanceX
                        const curWaterMarkTop = start + j * distanceY
                        // 以水印中心为原点 旋转
                        ctx.translate(curWaterMarkLeft + waterMarkWidth / 2, curWaterMarkTop + waterMarkHeight / 2);
                        ctx.rotate(angel * Math.PI / 180);
                        ctx.globalAlpha = 1 - tran / 100;
                        ctx.fillText(waterMark, - waterMarkWidth / 2, -waterMarkHeight / 2);
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                        j++
                    }
                    i++
                }
            }


            const objectUrl = await canvas2Img(canvas, 'image/png');
            download(objectUrl, file.name);
        }

    }

    function handleReplace(){

    }

    function renderPosCom() {
        if (posType === 2) {
            return (
                <>
                    <Row>
                        <Col span={4} className='text'>
                            左边
                        </Col>
                        <Col span={16}>
                            <Slider
                                min={0}
                                max={100}
                                step={1}
                                onChange={setOffsetLeft}
                                value={offsetLeft}
                            />
                        </Col>
                        <Col span={4} className='text' style={{ justifyContent: 'flex-end' }}>{offsetLeft}%</Col>
                    </Row>
                    <Row>
                        <Col span={4} className='text'>
                            顶部
                        </Col>
                        <Col span={16}>
                            <Slider
                                min={0}
                                max={100}
                                step={1}
                                onChange={setOffsetTop}
                                value={offsetTop}
                            />
                        </Col>
                        <Col span={4} className='text' style={{ justifyContent: 'flex-end' }}>{offsetTop}%</Col>
                    </Row>
                </>

            )
        }

        if (posType === 1) {
            return (
                <div className='grid-selector-container'>
                    <GridSelector value={cellIndex} onChange={handleCellIndexChange} />
                </div>
            )

        }
    }

    return (
        <div className='sidebar-container'>
            <div className='setting-container'>
                <div className='line-center'>
                    <Radio.Group style={{ width: '100%' }} value={waterMarkType} onChange={(e) => setWaterMarkType(e.target.value)}>
                        <Radio.Button style={{ width: '50%' }} value="text">文字水印</Radio.Button>
                        <Radio.Button style={{ width: '50%' }} value="image">图片水印</Radio.Button>
                    </Radio.Group>
                </div>
                <div className='line-left label'>文本添加</div>
                <textarea className='sidebar-textarea' placeholder='请输入...' rows={5} value={waterMark} onChange={(e) => setWaterMark(e.target.value)} >
                </textarea>
                <div className='line-center'>

                </div>
                <Space style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
                    <Select style={{ width: '140px' }} value={fontName} onChange={setFontName} options={[
                        {
                            value: 'Microsoft YaHei',
                            label: '微软雅黑',
                        },
                        {
                            value: 'SimSun',
                            label: '宋体',
                        },
                        {
                            value: 'SimHei',
                            label: '黑体',
                        },
                        {
                            value: 'Arial Black',
                            label: 'Arial Black',
                        }, {
                            value: 'PingFang SC',
                            label: '苹方',
                        }, {
                            value: 'STHeiti',
                            label: '华文黑体'
                        }
                    ]} />
                    <Select value={fontSize} onChange={setFontSize} options={[
                        {
                            value: 14,
                            label: '14px',
                        },
                        {
                            value: 16,
                            label: '16px',
                        },
                        {
                            value: 18,
                            label: '18px',
                        },
                        {
                            value: 20,
                            label: '20px',
                        },
                        {
                            value: 24,
                            label: '24px',
                        },
                        {
                            value: 28,
                            label: '28px',
                        },
                        {
                            value: 36,
                            label: '36px',
                        },
                        {
                            value: 48,
                            label: '48px',
                        },
                        {
                            value: 64,
                            label: '64px',
                        },
                        {
                            value: 72,
                            label: '72px',
                        },
                        {
                            value: 128,
                            label: '128px',
                        }
                    ]} />
                    <ColorPicker value={fontColor} onChange={handleColorChange} />
                </Space>
                <div className='line-left label'>布局</div>
                <Row>
                    <Col span={4} className='text'>
                        方向
                    </Col>
                    <Col span={16}>
                        <Slider
                            min={-180}
                            max={180}
                            step={10}
                            onChange={setAngel}
                            value={typeof angel === 'number' ? angel : 0}
                        />
                    </Col>
                    <Col span={4} className='text' style={{ justifyContent: 'flex-end' }}>{angel}°</Col>
                </Row>
                <Row>
                    <Col span={4} className='text'>
                        透明度
                    </Col>
                    <Col span={16}>
                        <Slider
                            min={0}
                            max={100}
                            step={1}
                            onChange={setTran}
                            value={tran}
                        />
                    </Col>
                    <Col span={4} className='text' style={{ justifyContent: 'flex-end' }}>{tran}%</Col>
                </Row>
                <Row style={{ margin: '12px 0' }}>
                    <Radio.Group onChange={handlePosTypeChange} value={posType}>
                        <Radio value={0}>平铺</Radio>
                        <Radio value={1}>九宫格</Radio>
                        <Radio value={2}>自定义</Radio>
                    </Radio.Group>
                </Row>
                {renderPosCom()}
            </div>

            <div className='near-bottom'>
                <Button size='large' style={{ marginRight: '16px' }} onClick={handleReplace}>批量替换</Button>
                <Button size='large' type='primary' onClick={handleExport}>批量导出</Button>
            </div>
        </div>
    )
}

export default React.memo(Sidebar)