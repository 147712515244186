
import './Upload.css'
import { fileCenter } from '../../FileCenter';
import React, { useContext, useRef } from 'react';
import { FileCenterContext } from '../../FileCenterProvider';
import BatchUploadImg from '../images/batch_upload_img.svg';

function Upload() {

    const { setFileIdList } = useContext(FileCenterContext);
    const refFileInput = useRef(null)

    function handleClick() {
        // @ts-ignore
        refFileInput.current?.click()
    }

    function handlePrevent(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    function handleDrop(e) {
        const dt = e.dataTransfer;
        const files = dt.files;
        fileCenter.handleUpload(files, setFileIdList)
        handlePrevent(e)
    }

    function handleChange(event) {
        const files = event.target.files;
        fileCenter.handleUpload(files, setFileIdList)
    }

    return (
        <div className='drop-container' onClick={handleClick} onDragEnter={handlePrevent} onDragOver={handlePrevent} onDragLeave={handlePrevent} onDrop={handleDrop}>
            <input ref={refFileInput} className="file-input" type="file" accept="image/*" multiple onChange={handleChange} />
            <img src={BatchUploadImg} className='upload-img' alt='upload' width="100px" height="100px" />
            <div className='upload-text'>添加图片 或 拖拽到些区域</div>
        </div>
    )
}

export default React.memo(Upload)