import { FileCenterContext } from '../../FileCenterProvider';
import { getImageRatio, getTextWH } from '../../utils';
import './ImageEditView.css';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

let ratio = 1;

function ImageEditView(props) {
    const { selectedItem } = props;
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const refContainer = useRef(null);

    const {
        waterMark,
        fontName,
        fontSize,
        fontColor,
        angel,
        tran,
        posType,
        offsetLeft,
        offsetTop,
        cellIndex,
    }
        = useContext(FileCenterContext);

    const updateSize = useCallback(() => {
        const container = refContainer.current;
        if (!container) return;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        console.log(selectedItem);
        const image = selectedItem.image;
        const originWidth = image.width;
        const originHeight = image.height;
        ratio = getImageRatio(originWidth, originHeight, containerWidth, containerHeight);
        const adaptiveWidth = ratio * originWidth;
        const adaptiveHeight = ratio * originHeight;
        const left = (containerWidth - adaptiveWidth) / 2;
        const top = (containerHeight - adaptiveHeight) / 2;
        setLeft(left);
        setTop(top);
        setWidth(adaptiveWidth);
        setHeight(adaptiveHeight);
    }, [selectedItem]);

    useEffect(() => {
        window.addEventListener('resize', updateSize)
        return () => {
            window.removeEventListener('resize', updateSize)
        }
    }, [updateSize])

    useEffect(() => {
        updateSize()
    }, [selectedItem])


    const curFontSize = fontSize * ratio
    const { width: waterMarkWidth, height: waterMarkHeight } = getTextWH(waterMark, { fontFamily: fontName, fontSize: curFontSize })

    const waterMarkLeft = offsetLeft / 100 * width - waterMarkWidth / 2
    const waterMarkTop = offsetTop / 100 * height - waterMarkHeight / 2
    const opacity = 1 - tran / 100
    console.log('cellIndex ', cellIndex)

    function renderWaterMark() {
        if (posType !== 0) {
            return (
                <div className='water-mark' style={
                    {
                        color: fontColor,
                        left: `${waterMarkLeft}px`,
                        top: `${waterMarkTop}px`,
                        fontSize: `${curFontSize}px`,
                        fontFamily: `"${fontName}" ,sans-serif`,
                        transform: `rotate(${angel}deg)`,
                        opacity,
                    }
                }>{waterMark}</div>
            )
        }


        const list = []
        const start = curFontSize
        const distanceX = curFontSize * 2 + waterMarkWidth
        const distanceY = curFontSize * 2 + waterMarkHeight
        let i = 0;
        while (start + i * distanceX < width) {
            let j = 0;
            while (start + j * distanceY < height) {
                const curWaterMarkLeft = start + i * distanceX
                const curWaterMarkTop = start + j * distanceY
                list.push(
                    <div key={`${i}-${j}`} className='water-mark' style={
                        {
                            color: fontColor,
                            left: `${curWaterMarkLeft}px`,
                            top: `${curWaterMarkTop}px`,
                            fontSize: `${curFontSize}px`,
                            fontFamily: `"${fontName}" ,sans-serif`,
                            transform: `rotate(${angel}deg)`,
                            opacity,
                        }
                    }>{waterMark}</div>
                );
                j++
            }
            i++
        }
        return list
    }

    return (
        <div className='image-edit-view' ref={refContainer}>
            <img className='image-edit-img' src={selectedItem.fileDataUrl} alt='aaa' />
            <div className='image-real-size' style={{ left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px` }}>
                {waterMark && renderWaterMark()}
            </div>
        </div>
    )
}

export default React.memo(ImageEditView)