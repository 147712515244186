
import './WorkSpace.css'
import React, { useContext, useState } from 'react';
import Upload from './Upload';
import ThumbnailList from './ThumbnailList'
import ImageEditView from './ImageEditView'
import { FileCenterContext } from '../../FileCenterProvider';
import { fileCenter } from '../../FileCenter';

function WorkSpace() {

    const [selectedFileInfo, setSelectedFileInfo] = useState(null)
    const { fileIdList } = useContext(FileCenterContext);

    function handleClick(fileInfo) {
        setSelectedFileInfo(fileInfo)
    }

    if (fileIdList.length === 0) {
        return (
            <div className="workspace-container">
                < Upload />
            </div>
        )
    }

    const fileInfo = fileCenter.getFileInfo(fileIdList[0])
    // 刚开始用户没有点击右侧的缩略图列表，则默认选中第0个
    const selectedItem = selectedFileInfo || fileInfo
    return (
        <div className="workspace-container">
            <ThumbnailList selectedItem={selectedItem} onClick={handleClick} />
            <ImageEditView selectedItem={selectedItem} />
        </div>
    )

}

export default React.memo(WorkSpace)